<template>
	<div id="login" class="input-heading" :class="isLoggedOn ? 'logged-on' : 'not-logged-on'">
		<div class="login-status" v-if="isLoggedOn">
			<span>{{ displayName }}</span>
			<span>{{ casinoName }}</span>
			<button class="btn" type="button" @click="showLogoutDialog()">{{ languageStrings.logout }}</button>
		</div>
		<div class="input-section" v-if="!isLoggedOn">
			<div v-if="!casinoList.length > 0" id="casino-list-loading">
				<span class="loading-icon"></span>
				{{ languageStrings.loadingCasinos }}
			</div>
			<input
				type="tel"
				pattern="[0-9]+"
				:name="languageStrings.username"
				@keydown.enter="login()"
				v-model="username"
				:placeholder="languageStrings.phoneNumber"
				autocomplete="username"
			/>
			<input
				:type="unhidePassword ? 'text' : 'password'"
				:name="languageStrings.password"
				@keydown.enter="login()"
				v-model="password"
				:placeholder="languageStrings.password"
				autocomplete="new-password"
			/>
			<span
				class="eye-span"
				@click="unhidePassword = unhidePassword ? false : true"
				:class="unhidePassword ? 'hidden' : ''"
				:title="unhidePassword ? languageStrings.hidePassword : languageStrings.unHidePassword"
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
					<path
						d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
					/>
					<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
				</svg>
			</span>
			<button class="login btn" type="button" @click="login()">{{ languageStrings.login }}</button>
		</div>
	</div>
	<dialog id="logout-dialog">
		<div>
			<p id="logout-text">
				{{ `Are you sure you want to logout, ${this.displayName}` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="logout()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import { mapMutations, mapState, mapActions } from "vuex";
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "Login",
	props: {
		cashierState: Object,
		appDataBus: Object,
		casinoList: Array,
		systemSettings: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	watch: {
		isLoggedOn() {
			router.push("/");
		},
		cashierState: {
			handler(val, oldVal) {
				this.updateCasinoInfo();
			},
			deep: true,
		},
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			isLoggedOn: false,
			activeSession: this.session.get(),
			accessToken: "",
			accessTokenExpiration: "",
			refreshToken: "",
			status: Object.assign({}, this.globalStatus),
			userPermissions: {
				userId: "",
				globalPermissions: "",
				sitePermissions: {},
			},
			sitePermissions: {},
			isCashier: false,
			isReporter: false,
			isSiteAdmin: false,
			isManager: false,
			username: "",
			displayName: "",
			password: "",
			userId: "",
			casinoId: "",
			testing: "",
			casinoName: "",
			userPermissions: "",
			usersList: [],
			unhidePassword: false,
			inputDialog: null,
		};
	},
	created() {
		this.eventBus.on("forceLogout", () => {
			console.warn("forceLogout called");
			this.logout(true);
		});
	},
	async mounted() {
		this.casinoId = this.casinoList.length > 0 ? 1 : null;
		this.isLoggedOn = this.cashierState?.isLoggedOn || false;
		this.userId = this.cashierState?.userId || "";
		this.username = this.cashierState?.username || "";
		this.displayName = this.cashierState?.displayName || "";
		this.userPermissions = this.cashierState?.userPermissions || {};
		this.sitePermissions = this.cashierState?.userPermissions?.sitePermissions || {};
		if (Object.keys(this.sitePermissions).length > 0) {
			this.isSiteAdmin = this.sitePermissions[this.cashierState.casinoId]?.includes("SiteAdmin") ? true : false;
			this.isCashier = this.sitePermissions[this.cashierState.casinoId]?.includes("Cashier") ? true : false;
			this.isReporter = this.sitePermissions[this.cashierState.casinoId]?.includes("Reporter") ? true : false;
			this.isManager = this.sitePermissions[this.cashierState.casinoId]?.includes("Manager") ? true : false;
		}
		this.inputDialog = document.getElementById("logout-dialog");
	},
	methods: {
		casinoListForUser() {},
		updateCasinoInfo() {
			if (this.cashierState?.isLoggedOn) {
				this.isLoggedOn = this.cashierState.isLoggedOn;
				this.casinoId = this.cashierState.casinoId;
				this.casinoName = this.cashierState.casinoName;
				this.sitePermissions = this.cashierState.userPermissions?.sitePermissions || {};
			} else {
				this.isLoggedOn = false;
				this.casinoId = null;
				this.casinoName = "";
				this.sitePermissions = {};
			}
		},
		async login() {
			let STATE = this.cashierState;

			if (this.username.match(/[^0-9]/)) {
				this.status.message = "Phone number must be numbers only.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}

			if (!this.username || !this.password) {
				this.status.message = "Please provide a valid phone number and password.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}

			try {
				let body = {
					phoneNumber: this.username.replace(/\D/g, ""),
					password: this.password,
				};

				let requestUrl = new URL("/api/v1/authentication/login", this.rabbitsfootHostUrl);
				let headerObj = new Headers();
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let request = new Request(requestUrl.toString(), {
					method: "POST",
					body: JSON.stringify(body),
					headers: headerObj,
				});
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = fetchStatus.code === 404 ? "Phone number is incorrect" : fetchStatus.message;
					fetchStatus.message = fetchStatus.code === 401 ? "Login is incorrect" : fetchStatus.message;
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}
				let dataJson = await response.json();

				let thisCasino = this.casinoList.filter((casino) => casino.id === this.casinoId)[0];

				STATE.accessToken = dataJson.accessToken;
				STATE.accessTokenExpiration = dataJson.accessTokenExpiration;
				STATE.refreshToken = dataJson.refreshToken;
				STATE.userId = dataJson.userId;
				STATE.username = this.username;
				this.permissionsSuspended = dataJson.permissions.arePermissionsSuspended;
				STATE.displayName = this.displayName = dataJson.displayName;
				STATE.isLoggedOn = this.isLoggedOn = true;
				this.userPermissions = dataJson.permissions;
				this.sitePermissions = this.userPermissions.sitePermissions;
				STATE.userPermissions = this.userPermissions;
				this.isSiteAdmin = this.sitePermissions?.[STATE.casinoId]?.includes("SiteAdmin") ? true : false;
				this.isCashier = this.sitePermissions?.[STATE.casinoId]?.includes("Cashier") ? true : false;
				this.isReporter = this.sitePermissions?.[STATE.casinoId]?.includes("Reporter") ? true : false;
				this.isManager = this.sitePermissions?.[STATE.casinoId]?.includes("Manager") ? true : false;
				this.isHelpDesk = this.userPermissions.globalPermissions?.includes("HelpDesk") ? true : false;
				STATE.permissionsSuspended = this.permissionsSuspended;
				STATE.isSiteAdmin = this.isSiteAdmin;
				STATE.isCashier = this.isCashier;
				STATE.isReporter = this.isReporter;
				STATE.isManager = this.isManager;
				STATE.isHelpDesk = this.isHelpDesk;
				STATE.systemSettings = this.systemSettings;

				this.activeSession = STATE;

				this.password = "";
				this.unhidePassword = false;
				this.eventBus.emit("loginSucess");
				this.eventBus.emit("updateStatus", fetchStatus);
				this.eventBus.emit("updateCashierState", STATE);
			} catch (e) {
				console.error(e);
			}
		},
		logout() {
			
			this.session.deleteAll();
			this.isLoggedOn = this.isCashier = this.isReporter = this.isSiteAdmin = this.isManager = false;
			this.displayName = this.username = "";
			this.userPermissions = this.activeSession = {};
			this.unhidePassword = false;
			this.eventBus.emit("toggleNavBar", true);
			this.eventBus.emit("updateCashierState", this.activeSession);
			this.closeModal();
			router.push("/");	
		},

		showLogoutDialog() {
			this.inputDialog.showModal();
		},

		closeModal(){
			this.inputDialog.close();
		}
	},
};
</script>

<!-- scoped attribute to limit CSS to this component only -->
<style scoped>
#login.logged-on {
	position: absolute;
	top: 230px;
	right: 0;
	width: 80%;
}

#login.not-logged-on {
	display: grid;
	/* position: absolute; */
	top: 108px;
	right: 0;
	width: 100vw;
	height: calc(100vh - 150px);
	backdrop-filter: blur(6px);
	background-color: rgb(0 0 0 / 75%);
}

#login .input-section {
	background: rgb(71 68 196 / 40%);
	padding: 30px;
	position: relative;
	top: 0;
	/* left: 10vw; */
	/* backdrop-filter: blur(8px); */
	border-radius: 12px;
	box-shadow: inset -1px -1px 15px 1px rgb(13 28 37 / 50%);
	max-width: 30em;
}

.login-status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: fixed;
	top: 0;
	right: 0;
	padding: 5px 10px;
	font-size: 0.625em;
	background-color: #32373f;
	border-radius: 0 0 0 0.4em;
	border: 1px #5b88c0 solid;
}

.login-status button {
	user-select: none;
	margin: 8px auto;
	padding: 5px;
	min-width: 5em;
}

label[for="casinoId"] {
	text-align: center;
	text-transform: uppercase;
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

#casino-list-loading {
	display: block;
	padding: 0;
	margin: auto;
}

.login {
	margin: 5px auto;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.eye-span {
		margin-top: 10px;
	}
}

.eye-span::after {
	content: "I";
	position: absolute;
	right: 8px;
	top: -7px;
	transform: rotate(45deg);
	font-size: 2em;
	font-weight: bold;
}

.eye-span {
	position: absolute;
	top: 78px;
	right: 40px;
	color: #000;
	cursor: pointer;
}

.eye-span .eye {
	width: 1.5em;
	height: auto;
}

.eye-span.hidden {
	color: #008000;
}

.eye-span.hidden::after {
	content: none;
}

#logout-dialog {
	/* margin: auto 15px; */
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}
#btn-container {
	display: flex;
}

#logout-dialog {
		margin: auto;
}

#logout-text {
	text-align: center;
}


@media (min-width: 768px) {
	#login.not-logged-on {
		position: absolute;
	}
}
</style>
