<template>
	<div id="nav">
		<p style="text-align: center" v-if="cashierState?.displayName">Welcome, {{ cashierState.displayName }}</p>
		<nav aria-label="main menu" v-if="cashierState?.isLoggedOn">
			<ul>
				<li>
					<router-link to="/" @click="toggleNavBar()">{{ languageStrings.selectCasino }}</router-link>
				</li>
				<li>
					<router-link
						v-if="!cashierState?.permissionsSuspended && cashierState?.isCashier && cashierState?.casinoId"
						to="/openCashierBank"
						@click="toggleNavBar()"
						>{{ languageStrings.openBank }}</router-link
					>
				</li>
				<li>
					<router-link
						v-if="
							cashierState?.isLoggedOn &&
								!cashierState?.permissionsSuspended &&
								cashierState?.casinoId &&
								(cashierState?.isCashier || cashierState?.isSiteAdmin || cashierState?.isManager || cashierState?.isHelpDesk)
						"
						to="/listCashierBanks"
						@click="toggleNavBar()"
						>{{ languageStrings.listBanks }}</router-link
					>
				</li>
				<li v-if="devMode">
					<router-link to="/systemInfo" @click="toggleNavBar()">System Info</router-link>
				</li>
				<li>
					<router-link to="/about" @click="toggleNavBar()">{{ languageStrings.About }}</router-link>
				</li>
			</ul>
			<p style="text-align: center">{{ cashierState?.casinoName }}</p>
			<p v-if="!serialSupported" class="no-serial" title="No serial api support. Printer functionality is disabled.">No serial api support</p>
			<p v-if="devMode && !isMobile" class="diag-info">
				isLoggedOn: {{ cashierState?.isLoggedOn }} <br />
				casinoId: {{ cashierState?.casinoId }} <br />
				isCashier: {{ cashierState?.isCashier }} <br />
				isSiteAdmin: {{ cashierState?.isSiteAdmin }} <br />
				userId: <span style="user-select: all">{{ cashierState?.userId }}</span>
			</p>
			<p v-if="devMode" class="dev-mode">Client running in development mode</p>
		</nav>
	</div>
</template>

<script>
export default {
	name: "MainNavbar",
	props: {
		cashierState: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	methods: {
		toggleNavBar() {
			this.eventBus.emit("toggleNavBar");
		},
		logout() {
			this.eventBus.emit("regularLogout");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.casino-name {
	position: relative;
	bottom: 0;
	text-align: center;
	color: #000;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
	/* overflow-wrap: anywhere; */
	text-overflow: ellipsis;
	overflow: hidden;
}

#nav a.router-link-exact-active {
	font-weight: normal;
	color: #fff;
}

#nav {
	position: fixed;
	top: 80px;
	left: 0;
	bottom: 0;
	max-height: 0;
	width: 100%;
	background-color: #bccfe5;
	overflow: hidden auto;
	list-style: none;
	padding: 0;
	transition: max-height 0.3s;
	z-index: 100;
	box-shadow: 0px 3px 3px rgb(0 0 0 / 50%);
}

#nav.open {
	max-height: 80vh;
}

#nav.open.dev-mode {
	max-height: 525px;
}

@media (min-width: 768px) {
	#nav {
		position: relative;
		width: 100%;
		top: unset;
		float: left;
		height: calc(100vh - 150px);
		max-height: unset;
		box-shadow: 1px 0 6px rgb(0 0 0/50%);
		z-index: unset;
	}
}

#nav a {
	font-weight: bold;
}

nav ul {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}

nav a {
	display: block;
	background-color: #5b88c0;
	margin: 10px;
	padding: 10px;
	border-radius: 6px;
	text-decoration: none;
	transition: 0.3s;
	color: #dceeff;
	user-select: none;
	cursor: pointer;
	box-shadow: inset -1px -1px 15px 0px rgb(0 0 0 / 40%);
}

nav a:hover,
.login-link:hover {
	transform: scale(1.025);
	background-color: #4971a4;
	color: white;
}

.router-link-active,
.router-link-active:hover {
	transform: scale(1);
	background-color: green;
	box-shadow: inset 0px 0px 14px rgb(0 0 0 / 44%);
	cursor: default;
}

@media (min-width: 768px) {
	#nav.open,
	#nav.open.dev-mode {
		max-height: unset;
	}
}
</style>
