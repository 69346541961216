<template>
	<footer>
		<h3 v-if="casinoGroupName">{{ casinoGroupName }}</h3>
		<h3 v-else>&nbsp;</h3>
		<div class="versions" v-if="!isMobile">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	props: {
		serverVersion: Object,
		isMobile: Boolean,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.versions {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 2px;
    left: 0;
    margin: 0 15px;
    font-size: .8em;
}
.versions span {
	user-select: all;
}
</style>
