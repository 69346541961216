import { createRouter, createWebHistory } from "vue-router";
import SelectCasino from '../views/SelectCasino.vue'


const routes = [
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: '/',
    name: 'selectCasino',
    component: SelectCasino,
    meta: {
      title: 'Cashier Home | Select Casino'
    }
  },
  {
    path: '/openCashierBank',
    name: 'OpenCashierBank',
    component: () => import('../views/OpenCashierBank.vue'),
    meta: {
      title: 'Open Cashier Bank'
    }
  },
  {
    path: '/fillCashierBank',
    name: 'FillCashierBank',
    component: () => import('../components/FillCashierBank.vue'),
    meta: {
      title: 'Fill Cashier Bank'
    }
  },
  {
    path: '/fundsTransfer',
    name: 'FundsTransfer',
    component: () => import('../components/FundsTransfer.vue'),
    meta: {
      title: 'Funds Transfer'
    }
  },
  {
    path: '/listCashierBankFills',
    name: 'ListCashierBankFills',
    component: () => import('../components/ListCashierBankFills.vue'),
    meta: {
      title: 'List Cashier Bank Fills'
    }
  },
  {
    path: '/listTransfersfromCashierBank',
    name: 'ListTransfersfromCashierBank',
    component: () => import('../components/ListTransfersfromCashierBank.vue'),
    meta: {
      title: 'List Transfers from Cashier Bank'
    }
  },
  {
    path: '/listCashierBanks',
    name: 'ListCashierBanks',
    component: () => import('../views/ListCashierBanks.vue'),
    meta: {
      title: 'List Cashier Banks'
    }
  },
  {
    path: '/bankView',
    name: 'BankView',
    component: () => import('../views/BankView.vue'),
    meta: {
      title: 'Bank View'
    }
  },
  {
    path: '/fundsLockManager',
    name: 'FundsLockManager',
    component: () => import('../views/FundsLockManager.vue'),
    meta: {
      title: 'Funds Lock Manager'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About Cashier App | Documentation'
    }
  },
  {
    path: '/systemInfo',
    name: 'SystemInfo',
    component: () => import(/* webpackChunkName: "systemInfo" */ '../views/SystemInfo.vue'),
    meta: {
      title: 'System Info | Development Only'
    }
  },
]

// Use ./ as the routing base if we're in dev mode, or if the env variables
// say this app has a subdomain of its own.  Otherwise assume it's running
// in the ./cashier/ directory.
const useSubdirRouting = (process.env.VUE_APP_WEBCONFIG_TYPE !== 'subdomain') &&
  (process.env.NODE_ENV !== 'development');

const router = createRouter({
  history: createWebHistory(useSubdirRouting ? './cashier/' : './'),
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that was defined above.
  let title = to.meta.title;
  // If the route has a title, use it as the page title. If not, we'll just title it Cashier App.
  document.title = title ? title : "Cashier App";
  // Continue resolving the route
  next();
});

export default router
