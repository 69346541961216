import { createStore } from 'vuex'

export default createStore({
  state: {
    cashierState: {},
    casinoList: [],
    errorList: []
  },
  getters: {
    cashierState(state) {
      return state.cashierState;
    },
    casinoList(state) {
      return state.casinoList;
    },
    errorList(state) {
      return state.errorList;
    }
  },
  mutations: {
    updateCashierState(state, payload) {
      state.cashierState = payload;
    },
    updateCasinoList(state, payload) {
      state.casinoList = payload;
    },
    updateErrorList(state, payload) {
      state.errorList = payload;
    }
  },
  actions: {
    setCashierState(state, payload) {
      this.commit('updateCashierState', payload);
    },
    setCasinoList(state, payload) {
      this.commit('updateCasinoList', payload);
    },
    setErrorList(state, payload) {
      this.commit('updateErrorList', payload);
    }
  }
})