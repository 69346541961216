/**
 * Class for converting native JS strings to byte sequences for a given encoding.
 * The encodings here are all assumed to be single-byte encodings, so generally 
 * extensions of 7-bit ASCII.
 * 
 * Note that while JS has a TextDecoder class that can read from a huge variety
 * of encodings, the TextEncoder class only supports writing/encoding to 
 * UTF-8.
 * 
 * Instances of this class are initiallized with objects indicating the character-
 * to-byte mappings.  This file also exports at least one ready-made encoder
 * for a particular well-known encoding.
 */
class EightBitEncoder {
    #map;
    #auto7BitAscii;

    /**
     * Creates a new encoder with the given mapping.
     * @param {object} charToCodeMap an Object where the keys are strings containing one or more characters, and the 
     * values are the byte values that any of those characters should map to.
     * @param {boolean} auto7BitAscii If true, any character in the 7-bit ASCII range (including control characters)
     * will be encoded as its ASCII value.  No need to include those in charToCodeMap.
     */
    constructor(charToCodeMap, auto7BitAscii) {
        // Private members.
        this.#map = {};
        this.#auto7BitAscii = auto7BitAscii;

        // The input map object allows keys that have multiple characters, all of which should map to
        // a particular byte value.  We want to break those apart into single-character keys for easy lookup.
        for (const [key, codeVal] of Object.entries(charToCodeMap)) {
            if (typeof(key)!=="string" || key.length < 1)
                throw new Error("EightBitEncoder.constructor: charToCodeMap param keys must be non-empty strings");
            if (typeof(codeVal)!=="number" || isNaN(codeVal) || codeVal < 0 || codeVal > 255)
                throw new Error("EightBitEncoder.constructor: charToCodeMap param values must be numbers from 0 to 255 inclusive");
            
            for (var singleChar of key)
                this.#map[singleChar] = codeVal;
        }
    }

    /**
     * Returns the byte values that the given string encodes to.  Unmappable values are either ignored or replaced
     * depending on the replacementChar parameter.
     * @param {string} nativeString The string to encode
     * @param {string|undefined} replacementChar Either undefined if unmappable characters should be skipped, or 
     * a string containing a single character to use for all unmappable characters.
     * @returns Uint8Array containing the string encoded as byte values
     */
    encode(nativeString, replacementChar) {
        let codeForReplacement;
        if (typeof(replacementChar)==='string') {
            if (replacementChar.length != 1)
                throw new Error("EightBitEncoder.encode: replacementChar must be undefined or a 1-character string");
            codeForReplacement = this.codeForChar(replacementChar);
            if (replacementChar.length != 1)
                throw new Error("EightBitEncoder.encode: replacementChar must be mapped");
        } else if (replacementChar!==undefined) {
            throw new Error("EightBitEncoder.encode: replacementChar must be undefined or a 1-character string");
        }
        if (typeof(nativeString)!=='string')
            throw new Error("EightBitEncoder.encode: nativeString must be a string");

        let codeArr = new Uint8Array(nativeString.length);
        let arrIdx = 0;

        for (const char of nativeString) {
            let code = this.codeForChar(char);
            if (code===undefined)
                code = codeForReplacement;
            if (code===undefined)
                continue;
            codeArr[arrIdx] = code;
            arrIdx += 1;
        }

        return (arrIdx===nativeString.length)? codeArr : codeArr.slice(0, arrIdx);
    }

    /**
     * Determines whether all of the characters in the given string are mapped and can thus be encoded faithfully.
     * @param {string} nativeString The string to check.
     * @returns true if mappings exist for all characters in the given string.
     */
    canEncode(nativeString) {
        if (typeof(nativeString)!=="string")
            throw new Error("EightBitEncoder.canEncode: nativeString param must be a string");
        for (const singleChar of nativeString) {
            if (this.codeForChar(singleChar)===undefined)
                return false;
        }
        return true;
    }

    /**
     * Gets the mapping for a single character.
     * @param {string} singleChar A string whose first character is mapped.
     * @returns Byte value of the character in this encoding.
     */
    codeForChar(singleChar) {
        const nativeCharCode = singleChar.charCodeAt(0);
        if (this.#auto7BitAscii && nativeCharCode>=0 && nativeCharCode<=127)
            return nativeCharCode;
        return this.#map[singleChar];
    }
}

/**
 * Input map for win1252Encoder, below.
 */
const win1252Map = {
    "€": 128, 
    /* n/a 129*/
    "‚": 130,
    "ƒ": 131,
    "„": 132,
    "…": 133,
    "†": 134,
    "‡": 135,
    "ˆ": 136,
    "‰": 137,
    "Š": 138,
    "‹": 139,
    "Œ": 140,
    /* n/a 141*/
    "Ž": 142,
    /* n/a 143*/
    /* n/a 144*/
    "‘": 145,
    "’": 146,
    "“": 147,
    "”": 148,
    "•": 149,
    "–": 150,
    "—": 151,
    "˜": 152,
    "™": 153,
    "š": 154,
    "›": 155,
    "œ": 156,
    /* n/a 157*/
    "ž": 158,
    "Ÿ": 159,
    /* n/a 160*/
    "¡": 161,
    "¢": 162,
    "£": 163,
    "¤": 164,
    "¥": 165,
    "¦": 166,
    "§": 167,
    "¨": 168,
    "©": 169,
    "ª": 170,
    "«": 171,
    "¬": 172,
    /* n/a 173*/
    "®": 174,
    "¯": 175,
    "°": 176,
    "±": 177,
    "²": 178,
    "³": 179,
    "´": 180,
    "µ": 181,
    "¶": 182,
    "·": 183,
    "¸": 184,
    "¹": 185,
    "º": 186,
    "»": 187,
    "¼": 188,
    "½": 189,
    "¾": 190,
    "¿": 191,
    "À": 192,
    "Á": 193,
    "Â": 194,
    "Ã": 195,
    "Ä": 196,
    "Å": 197,
    "Æ": 198,
    "Ç": 199,
    "È": 200,
    "É": 201,
    "Ê": 202,
    "Ë": 203,
    "Ì": 204,
    "Í": 205,
    "Î": 206,
    "Ï": 207,
    "Ð": 208,
    "Ñ": 209,
    "Ò": 210,
    "Ó": 211,
    "Ô": 212,
    "Õ": 213,
    "Ö": 214,
    "×": 215,
    "Ø": 216,
    "Ù": 217,
    "Ú": 218,
    "Û": 219,
    "Ü": 220,
    "Ý": 221,
    "Þ": 222,
    "ß": 223,
    "à": 224,
    "á": 225,
    "â": 226,
    "ã": 227,
    "ä": 228,
    "å": 229,
    "æ": 230,
    "ç": 231,
    "è": 232,
    "é": 233,
    "ê": 234,
    "ë": 235,
    "ì": 236,
    "í": 237,
    "î": 238,
    "ï": 239,
    "ð": 240,
    "ñ": 241,
    "ò": 242,
    "ó": 243,
    "ô": 244,
    "õ": 245,
    "ö": 246,
    "÷": 247,
    "ø": 248,
    "ù": 249,
    "ú": 250,
    "û": 251,
    "ü": 252,
    "ý": 253,
    "þ": 254,
    "ÿ": 255
};

/**
 * Encoder for Windows-1252, a very widely-used 8-bit encoding with lots of currency symbols.  
 * (And letters with dots and squiggles, if you're into that sort of thing.)
 * https://en.wikipedia.org/wiki/Windows-1252
 */
const win1252Encoder = new EightBitEncoder( win1252Map, true);

export { 
    EightBitEncoder,
    win1252Encoder
};
