<template>
	<link
		v-if="countryCode == 'US' || countryCode == 'JP'"
		rel="stylesheet preconnect"
		as="style"
		href="https://fonts.googleapis.com/css?family=Roboto&amp;display=block"
	/>
	<link
		v-if="countryCode == 'MX'"
		rel="stylesheet preconnect"
		as="style"
		href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&display=swap"
	/>
	<Header
		:casinoName="cashierState?.casinoName"
		:status="status"
		:isMobile="isMobile"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
	/>
	<div id="nav-container" :class="isMobile ? 'isMobile' : ''">
		<button id="navbar-button" v-if="isMobile && cashierState?.isLoggedOn" @click="toggleNavBar()" class="nav-button-container btn">
			<div></div>
			<div></div>
			<div></div>
		</button>
		<MainNavbar :class="navBarClass" :cashierState="cashierState" :isMobile="isMobile" :languageStrings="languageStrings" />
	</div>
	<router-view
		id="router-view"
		:key="$route.fullPath"
		:casinoList="casinoList"
		:cashierState="cashierState"
		:forceChildComponent="forceChildComponent"
		:appDataBus="appDataBus"
		:serverVersion="serverVersion"
		:isMobile="isMobile"
		:portExists="portExists"
		:inGameCurrencyTool="inGameCurrencyTool"
		:systemCurrencyTool="systemCurrencyTool"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
	/>
	<div v-if="serialSupported && !portExists && $route.fullPath == '/'" class="serial-button-container">
		<p>Click below to grant permissions for serial port access. This only has to be done once to enable printer.</p>
		<button @click="checkAndSetSerial()" class="btn">Enable Serial Port</button>
	</div>
	<div v-if="!isMobile && serialSupported && portExists && $route.fullPath == '/'" class="serial-button-container">
		<p>Printer is enabled.</p>
	</div>
	<Login
		:cashierState="cashierState"
		:casinoList="casinoList"
		:systemSettings="systemSettings"
		:isMobile="isMobile"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
	/>
	<Footer :serverVersion="serverVersion" :isMobile="isMobile" />
</template>

<script>
// @ is an alias to /src
import { ref, reactive } from "vue";
import { mapMutations, mapState, mapActions } from "vuex";
import store from "@/store/index";
import Header from "@/components/Header";
import MainNavbar from "@/components/MainNavbar";
import Login from "@/components/Login";
import Footer from "@/components/Footer";
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";
import CurrencyTool from "@/dependencies/currencyTool";
import { win1252Encoder } from "@/dependencies/eightBitEncoder";

export default {
	components: {
		MainNavbar,
		Login,
		Header,
		Footer,
	},
	data() {
		return {
			languageStrings: require(`@/dependencies/${this.countryCode}.json`),
			languageErrorStrings: require(`@/dependencies/errors-${this.countryCode}.json`),
			status: Object.assign({}, this.globalStatus),
			windowWidth: window.innerWidth,
			isMobile: this.windowWidth < 768,
			mainNavbarOpen: false,
			navBarClass: "",
			cashierState: this.session.get() || {},
			money: this.currency,
			location: this.localeString,
			serverVersion: {},
			cashierBankId: "",
			isLoggedOn: false,
			globalPermissions: "",
			sitePermissions: [],
			isCashier: false,
			isReporter: false,
			isSiteAdmin: false,
			casinoName: "",
			casinoId: "",
			displayName: "",
			userId: "",
			currentBankId: "",
			casinoList: [],
			forceChildComponent: null,
			appDataBus: {},
			portExists: false,
			serialPort: {},
			callNumber: 0,
			openPorts: null,
			inGameCurrencyTool: null,
			systemCurrencyTool: null,
			systemSettings: {},
		};
	},
	watch: {
		windowWidth() {
			this.onResize();
		},
		portExists() {
			this.$nextTick(() => {
				this.$forceUpdate();
			});
		},
	},
	created() {
		this.getSerialPort();
		this.eventBus.on("toggleNavBar", (forceClose = false) => {
			if (forceClose) {
				this.mainNavbarOpen = false;
				this.navBarClass = "";
			} else {
				this.toggleNavBar();
			}
		});
		this.eventBus.on("requestRefreshPlayerCode", () => {
			this.cashierState.playerAccount.accessCodeConfirmed = false;
			this.cashierState.playerAccount.accountAccessCode = null;
			this.eventBus.emit("updateCashierState", this.cashierState);
			this.status.ok = false;
			this.status.message = this.languageErrorStrings.accountAccessCodeExpired;
			this.eventBus.emit("updateStatus", this.status);
			this.forceChildComponent = "PlayerIdentityAuthentication";
			router.push("/bankView");
		});
		this.eventBus.on("updateAppDataBus", (payload) => {
			// appDataBus intended to pass any data properties across components through reactive props
			// designed to take single properties or many passed into the eventBus payload
			// this is stateless and resides in the app instance and is not added to the Vuex Store or sessionStorage
			// intended for non-persistent data handling across components
			let payloadKeys = Object.keys(payload);
			payloadKeys.forEach((key) => {
				this.appDataBus[key] = payload[key];
			});
		});
		this.eventBus.on("removeAppDataBus", (removeKeys) => {
			// removeKeys takes an array of the keys for the propeties to be removed
			// or string for single property removal
			if (Array.isArray(removeKeys)) {
				removeKeys.forEach((key) => {
					delete this.appDataBus[key];
				});
			}
			if (typeof removeKeys === "string") {
				delete this.appDataBus[removeKeys];
			}
		});
		this.eventBus.on("updateCashierState", (payload) => {
			let mergedCashierState = {};
			if (Object.keys(payload).length !== 0) {
				mergedCashierState = {
					...this.cashierState,
					...payload,
				};
			}
			this.inGameCurrencyTool = new CurrencyTool(this.cashierState.currencyInfo, this.localeString);
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			this.cashierState = mergedCashierState;
			store.dispatch("setCashierState", mergedCashierState);
			this.session.save(mergedCashierState);
			this.forceChildComponent = mergedCashierState.forceChildComponent || "GetAccountAccessCode";
			if (!this.cashierState.isLoggedOn) {
				router.push("/");
			}
		});
		this.eventBus.on("updateErrorList", (payload) => {
			// payload.created = new Date(payload.created).toLocaleTimeString([], this.dateOptions);
			let newErrorList = JSON.parse(sessionStorage.getItem("errorList")) || store.state.errorList;
			if (newErrorList.length > 0 && newErrorList.length > 99) newErrorList.pop();
			newErrorList.push(payload);
			store.dispatch("setErrorList", newErrorList);
			sessionStorage.setItem("errorList", JSON.stringify(newErrorList));
		});
		this.eventBus.on("printTicket", (payload) => {
			if (this.serialSupported && this.portExists) this.printTicket(payload);
		});
		this.eventBus.on("resetCurrencyToolConstructor", (currencyInfo) => {
			// This tool passes currencyInfo to the constructor for
			// in-game currency.
			this.inGameCurrencyTool = new CurrencyTool(currencyInfo, this.localeString);
			// This tool passes systemSettings.cashOutCurrency to the constructor for
			// real-world currency formatting info.
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
		});
	},
	async mounted() {
		const cssFile = `./styleOverrides/${this.countryCode}.css`; // load style overides for locale customizations
		const cssElement = document.createElement("link");
		cssElement.setAttribute("rel", "stylesheet");
		cssElement.setAttribute("type", "text/css");
		cssElement.setAttribute("href", cssFile);
		document.getElementsByTagName("head")[0].appendChild(cssElement);

		this.$nextTick(() => {
			window.addEventListener("resize", this.onResize);
			this.onResize();
		});
		let stateSessionStorage = this.session.get();
		if (!Object.keys(stateSessionStorage).length === 0) {
			this.eventBus.emit("updateCashierState", stateSessionStorage);
			this.eventBus.emit("resetCurrencyToolConstructor", stateSessionStorage.currencyInfo);
		}
		this.getSystemSettings();
		this.checkServerVersion();
		this.getCasinos();
		window.onpopstate = (e) => {
			// Force browser back button to home
			// Web history state has problems when we deploy in a subfolder
			// This is to fix the back button causing empty page to be displayed
			this.$router.push("/");
		};
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
			this.isMobile = this.windowWidth < 768 || false;
			this.mainNavbarOpen = false;
			this.navBarClass = "";
		},
		toggleNavBar() {
			this.mainNavbarOpen = this.mainNavbarOpen ? false : true;
			this.navBarClass = this.mainNavbarOpen ? "open" : "";
		},
		async checkServerVersion() {
			let requestUrl = new URL("/api/v1/version", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.serverVersion = dataJson;
			} catch (e) {
				console.error(e);
				console.error(this.languageErrorStrings.versionRequestNotResponding);
			}
		},
		async getSystemSettings() {
			let requestUrl = new URL("/api/v1/system/settings", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.systemSettings = dataJson;
			} catch (e) {
				console.error(e);
				console.error(this.languageErrorStrings.systemSettingsRequestNotResponding);
			}
		},
		async getCasinos() {
			try {
				// Get Casino List
				let response = await sharedScripts.getCasinoList(this);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				this.casinoList = await response.sort((a, b) => a.name.localeCompare(b.name));
				store.dispatch("setCasinoList", this.casinoList || []);
				this.eventBus.emit("casinoListRetrieved", await response);
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
				this.status.message = "Failed getting Casino List";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		async getSerialPort() {
			this.openPorts = await navigator?.serial?.getPorts();
			this.portExists = this.openPorts?.length > 0 && this.openPorts[0] instanceof Object;
			let state = this.cashierState;
			state.portExists = this.portExists;
			this.eventBus.emit("updateCashierState", state);
			return state.portExists ? this.openPorts[0] : false;
		},
		async checkAndSetSerial() {
			// check if app has access to serial port
			this.openPorts = await navigator.serial.getPorts();

			if (this.openPorts?.length > 0) {
				this.portExists = true;
				this.serialPort = this.openPorts[0];
			} else {
				// if no ports present make sure user has given serial port permissions to this app
				try {
					let requestPort = await navigator.serial.requestPort();
					if (requestPort) this.portExists = true;
					this.openPorts = await navigator.serial.getPorts();
					this.serialPort = this.openPorts[0];
				} catch (e) {
					this.status.ok = false;
					this.status.message = `${e.message} Printer functions disabled.`;
					this.eventBus.emit("updateStatus", this.status);
					console.error(e);
					if (e.name === "NotFoundError") this.portExists = false;
				}
			}

			// make sure port is closed or this.serialPort.open() call might fail later.
			// The writable property is null when the port is closed.
			if (this.openPorts?.length > 0 && this.serialPort?.writable) {
				try {
					await this.serialPort.close();
				} catch (e) {
					console.warn(e.name === "InvalidStateError" ? "The port is already closed." : "Unknown Error");
				}
			}
		},
		async printTicket(ticketString) {
			this.serialPort = await this.getSerialPort();
			try {
				// Use the Windows-1252 encoding.  Elsewhere when we build the print data, after sending a
				// reset, we're including instructions to switch to that code page.  The printer supports
				// others, but this one seems the best general purpose one for currency symbols.
				let encoder = win1252Encoder;

				// Check if serial port is already open.
				// The writable property is null when the port is closed.
				if (!this.serialPort?.writable) await this.serialPort.open({ baudRate: 38400 });

				// Check whether the printer is ready.
				// getSignals() returns an object with four properties.
				// The dataSetReady boolean indicates if the device is ready to send and receive data.
				// https://developer.mozilla.org/en-US/docs/Web/API/SerialPort/getSignals
				let portSignals = await this.serialPort.getSignals();

				if (this.serialSupported && !portSignals.dataSetReady) {
					this.status.ok = false;
					this.status.message = `The Printer is not ready.`;
					this.eventBus.emit("updateStatus", this.status);
					return false;
				}

				let writer = await this.serialPort.writable.getWriter();

				await writer.write(encoder.encode(ticketString));

				await writer.close();
				await this.serialPort.close().then(() => {
					console.log("serial port closed");
				});
			} catch (e) {
				this.status.ok = false;
				this.status.message = `Serial port error message: ${e.name}`;
				this.eventBus.emit("updateStatus", this.status);
				console.warn(e.name);
				console.error(e);
				return false;
			}
		},
		async getPrinterStatus() {
			// **** This function is not currently being called ****
			// **** I was working on functionality to check printer status ****
			// **** We may need to implement this in the future so I didn't want to lose the work so far ****
			// **** This function does return codes but they seem to differ from Epson documentation so it shoudn't be used for now ****
			// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=122

			let commandCode = `${this.GS}a`;

			try {
				let encoder = win1252Encoder;
				this.openPorts = await navigator.serial.getPorts();
				await this.openPorts[0].open({ baudRate: 38400 });
				let writer = await this.openPorts[0].writable.getWriter();
				let printerStatus = await writer.write(encoder.encode(commandCode));
				console.log(await printerStatus);
				await writer.close();

				while (this.openPorts[0].readable) {
					const reader = this.openPorts[0].readable.getReader();
					console.log(reader);
					try {
						while (true) {
							const { value, done } = await reader.read();
							if (done) {
								// |reader| has been canceled.
								break;
							}
							console.log(await value);
						}
					} catch (e) {
						console.warn(e.name);
						console.error(e);
					} finally {
						reader.releaseLock();
					}
				}
				await this.openPorts[0].close().then(() => {
					console.log("serial port closed");
				});
			} catch (e) {
				console.warn(e.name);
				console.error(e);
			}
		},
	},
};
</script>

<style>
#router-view {
	/* position: relative; */
	position: fixed;
	top: 108px;
	width: 100vw;
	margin: auto;
	overflow: hidden auto;
	height: calc(100vh - 160px);
}

.dev-mode {
	position: absolute;
	bottom: 0;
	width: 100%;
	color: #f00;
	text-align: center;
	font-weight: bold;
}

.no-serial {
	color: #f00;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	cursor: help;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.diag-info {
	position: fixed;
	top: 0;
	left: 15px;
	font-size: 0.8em;
	color: rgb(119, 119, 119);
}

#app {
	position: relative;
}
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
	overflow: hidden auto;
	color: #fff;
	background-color: #32373f;
}

.gradient-background {
	background: linear-gradient(31deg, #e90ef2, #3bcf5f, #76b6ea, #11f1ea);
	background-size: 240% 240%;
	animation: gradient-animation 8s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
	line-height: 1.5em;
}
h1,
.h1 {
	font-size: 2em;
}
h2,
.h2 {
	font-size: 1.875em;
}
h3,
.h3 {
	font-size: 1.5em;
}
h4,
.h4 {
	font-size: 1.125em;
}
h5,
.h5 {
	font-size: 0.875em;
}
h6,
.h6 {
	font-size: 0.75em;
}
p {
	font-size: 1em;
}

a.aniUnderline {
	text-decoration: none;
	position: relative;
	display: inline;
}
a.aniUnderline:after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 0%;
	left: 50%;
	transform: translateX(-50%);
	border-bottom: 2px solid #3366ff;
	transition: 0.3s;
}
a.aniUnderline:hover:after {
	width: 100%;
}

/* #header-nav {
    position: absolute;
    top: 0;
    left: -410px;
    background: #5b88c0;
    transition: .3s;
    padding: 15px;
    border-radius: 0 12px 0 0;
    z-index: 1000;
}

#header-nav::after {
    content: '>';
    height: 32px;
    width: 32px;
    background-color: red;
    position: absolute;
    bottom: 0;
    right: -32px;
    text-align: center;
    font-size: 25px;
} */

/* #header-nav:hover {
    left: 0;
} */
#app {
	position: relative;
	overflow: hidden;
}

header {
	padding: 30px;
	background-color: #434250;
	color: #fff;
	box-shadow: 0px 1px 6px rgb(0 0 0 / 50%);
}

header h1,
footer h3 {
	margin: 0;
}

footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #434250;
}

/* #nav-container {
	position: relative;
	top: 0;
	width: 20%;
	height: calc(100vh - 150px);
	background-color: #bccfe5;
	float: left;
	box-shadow: 1px 0px 6px rgb(0 0 0 / 50%);
	overflow: hidden auto;
} */

#navigation {
	list-style: none;
}

#nav-container.isMobile {
	z-index: 5;
}

.nav-button-container {
	position: fixed !important;
	top: 20px;
	left: 15px !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 50px;
	min-width: unset;
	height: 50px;
	padding: 0;
	margin: 0;
	z-index: 1;
	border-radius: 100% !important;
	overflow: hidden;
}

.nav-button-container div {
	position: relative;
	left: -5px;
	margin: 3px;
	align-self: center;
	width: 30px;
	height: 4px;
	background-color: #fff;
	border-radius: 5px;
}

nav ul {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;
	padding: 0;
	/* background-color: #bccfe5; */
	list-style: none;
	overflow: hidden;
}

nav a,
.login-link,
.btn {
	display: block;
	background-color: #5b88c0;
	margin: 10px;
	padding: 10px;
	border-radius: 6px;
	border: 1px #fff solid;
	text-decoration: none;
	transition: 0.3s;
	color: #fff;
	user-select: none;
	cursor: pointer;
	box-shadow: inset -1px -1px 15px 0px rgb(0 0 0 / 40%);
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;
}

.login-link {
	position: absolute;
	top: 15px;
	right: 15px;
}

nav a:hover,
.login-link:hover,
.btn:hover {
	transform: scale(1.025) translateZ(0);
	background-color: #4971a4;
	color: white;
}
.input-section div {
	display: flex;
}
.input-section label,
.input-section input {
	flex-basis: 50%;
}
.input-section input.max-fifty {
	max-width: 50%;
}
input:invalid {
	border: 4px red solid;
}
.btn.inline {
	display: inline-flex;
	flex: 1 0 auto;
	text-align: center;
}
.btn.inline.active,
.router-link-active,
.router-link-active:hover {
	transform: scale(1);
	background-color: green;
	box-shadow: inset 1px 1px 5px rgb(0 0 0 / 50%);
	cursor: default;
}
input[type="checkbox"] {
	height: 20px;
	width: 20px;
}
.checkbox-button {
	display: inline-flex;
	align-items: center;
	width: auto;
	margin-left: 15px;
	padding: 0 5px;
}
.checkbox-button,
.checkbox-button label,
.checkbox-button input {
	cursor: pointer;
}
#main {
	width: 80%;
	margin-left: 20%;
	/* position: absolute;
	right: 0; */
	top: 110px;
	padding: 15px;
}

h2,
h3,
.align-center {
	text-align: center;
}

h3 {
	margin: 10px;
}

.scanner-container video {
	margin: auto;
	display: block;
}

.input-section {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin: auto;
}

fieldset {
	display: flex;
	flex-flow: column nowrap;
	width: 50%;
	margin: 15px auto;
}

label,
input,
select {
	margin: 8px;
	font-size: 1rem;
	font-weight: 700;
	border-radius: 6px;
}

button {
	display: block;
	margin: 15px auto;
	/* min-width: 10em; */
	max-width: 50%;
	font-size: 1em;
	border-radius: 6px;
	user-select: none;
	cursor: pointer;
	box-shadow: inset -1px -1px 15px 0px rgb(0 0 0 / 40%);
}

button.prev-button,
button.next-button {
	min-width: 6em;
}

ul {
	overflow-wrap: anywhere;
}

.header h1 {
	text-align: center;
}

.login {
	display: cancelThisProperty;
}

tr.open,
.bank-status {
	text-align: center;
	background-color: #283761;
	color: #fff;
	padding: 10px 15px;
	/* cursor: pointer; */
}

#bankOutput {
	position: fixed;
	bottom: 15px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	width: 100%;
}

tr.data-row.open:hover {
	background-color: #012fb8;
}

#paging {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;
	margin: auto;
	padding: 0 15px;
	user-select: none;
}

.disabled {
	opacity: 0.5;
}

.transactionReport,
.currentBalance {
	width: 50%;
	margin: 30px auto;
	text-align: left;
}

tr.header-row {
	position: sticky;
	background-color: #d7d7d7;
	color: #000;
	font-weight: 700;
	top: 0;
}

#nav-container::-webkit-scrollbar-track,
.bank-lists-container::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#nav-container::-webkit-scrollbar-track,
.bank-lists-container::-webkit-scrollbar {
	width: 32px;
}

#nav-container::-webkit-scrollbar-track,
.bank-lists-container::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

.serial-button-container {
	position: absolute;
	/* display: grid; */
	/* height: 75%; */
	align-content: center;
	justify-content: center;
	/* width: 100%; */
	margin: 0 auto;
	padding: 0 30px;
	color: #000;
	font-weight: 700;
	bottom: 0;
}

.serial-button-container p {
	padding: 15px 30px;
	margin: 5px auto;
	background-color: rgb(67 66 80/75%);
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
	color: #ff0;
	text-align: center;
	font-weight: 700;
}

.serial-button-container .btn {
	width: 12em;
	margin: auto;
}

.loading-icon {
	display: block;
	content: "";
	position: relative;
	top: 0;
	margin: 15px auto;
	height: 32px;
	width: 32px;
	border: 1px solid;
	border-radius: 100%;
	border-color: red white blue black;
	animation: loader 0.5s linear infinite;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.player-id,
.claim-agent {
	text-align: center;
	padding: 5px;
}

.player-id span,
.claim-agent span {
	padding: 5px;
	font-weight: bold;
	border-radius: 100%;
	filter: contrast(1.5);
}

/* Chrome, Safari, Edge, Opera - remove spinner */
/* Requested in bug report https://metagamingstudios.atlassian.net/browse/MGG-656 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.blink {
	animation: blinker 0.75s linear infinite;
}

@keyframes blinker {
	50% {
		background-color: orange;
	}
}

/* Firefox - remove spinner */
input[type="number"] {
	-moz-appearance: textfield;
}

[disabled] {
    cursor: wait;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#router-view {
		margin-bottom: 150px;
	}
}

@media (min-width: 768px) {
	#router-view {
		top: 108px;
		height: calc(100vh - 150px);
		width: 80%;
		margin-left: 20%;
		margin-top: 0;
	}

	#nav-container {
		position: relative;
		top: 0;
		width: 20%;
		height: calc(100vh - 150px);
		background-color: #bccfe5;
		float: left;
		box-shadow: 1px 0px 6px rgb(0 0 0 / 50%);
		overflow: hidden auto;
	}

	#nav[data-v-6204b185] {
		width: 100%;
		height: calc(100vh - 150px);
	}

	#paging {
		width: 38em;
	}

	h1 {
		font-size: 2em;
	}

	.operator-id,
	.cashier-id {
		text-align: center;
	}

	button {
		min-width: 10em;
	}

	.serial-button-container {
		width: 80%;
		margin-left: 20%;
	}
}
</style>
