<template>
	<header>
		<img
			v-if="countryCode == 'MX' && !isMobile"
			class="header-logo-sm"
			src="/styleOverrides/EncoreOnlinme_MX_LogoNav-p-800.png"
			alt="encoreonline.mx"
		/>
		<h1 v-if="!isMobile">
			{{ languageStrings.welcome }}<span v-if="casinoName"> to {{ casinoName }}</span>
		</h1>
		<h1 v-else>
			<span v-if="casinoName">{{ casinoName }}</span>
			<span v-else>{{ languageStrings.welcome }}</span>
		</h1>
	</header>
	<div class="notifications" v-if="notificationList.length > 0">
		<button class="dismiss-notify btn" title="Dismiss all notifications" @click="dismissNotifications()"></button>
		<table>
			<tr>
				<th>{{ languageStrings.timeText }}</th>
				<th>{{ languageStrings.notification }}</th>
			</tr>
			<tr v-for="(item, index) in notificationList" :key="index" :class="!item.ok ? 'error' : 'ok'">
				<td>{{ item.createdTimeString }}</td>
				<td v-if="item.message">{{ item.message }}</td>
				<td v-else>{{ item.code }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "Header",
	props: {
		casinoName: {
			type: String,
		},
		status: {
			type: Object,
		},
		isMobile: {
			type: Boolean,
		},
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			notificationList: [],
		};
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("updateStatus", (payload) => {
			let notify = Object.assign({}, payload);
			let date = new Date();
			notify.created = date.getTime();
			notify.createdTimeString = date.toLocaleTimeString(this.localeString);
			notify.createdDateTimeString = date.toLocaleTimeString([], thisInstance.dateOptions);
			if (notify.message && notify.message !== "Session from cache") {
				this.eventBus.emit("updateErrorList", notify);
				thisInstance.notificationList.push(notify);
			}
		});
	},
	mounted() {
		let thisInstance = this;
		setInterval(() => {
			thisInstance.updateNotifications();
		}, 1000);
	},
	methods: {
		dismissNotifications() {
			this.notificationList = [];
		},
		updateNotifications() {
			let date = new Date();
			// notification box cleanup
			if (this.notificationList.length > 0) {
				let timeOffset = date.getTime() - 15000;
				this.notificationList = this.notificationList.filter((notification) => {
					return notification.created > timeOffset;
				});
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	text-align: center;
}

.bank-status {
	position: absolute;
	top: 15px;
	left: 15px;
	padding: 10px 15px;
	border-radius: 6px;
	font-weight: 700;
	transition: background-color 0.3s ease, color 0.3s ease;
	z-index: 500;
}

.status-message {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}

.status-message {
	position: absolute;
	top: 15px;
	left: 15px;
	padding: 10px 15px;
	border-radius: 6px;
	font-weight: 700;
	transition: background-color 0.3s ease, color 0.3s ease;
	z-index: 500;
}

.status-message.ok,
.notifications tr.ok {
	background-color: rgb(0 255 0 / 65%);
	color: #000;
}

.status-message.error,
.notifications tr.error {
	background-color: rgb(255 0 0 / 65%);
	color: #fff;
}

.status-message span {
	display: block;
}

.notifications {
	position: fixed;
	margin: 15px;
	padding: 15px;
	top: unset;
	bottom: 0;
	left: 15px;
	background: rgb(0 0 0 / 60%);
	backdrop-filter: blur(5px);
	z-index: 10000;
	border-radius: 10px;
	width: 90%;
	display: flex;
	justify-content: center;
}

.notifications table {
	display: block;
	height: hidden auto;
	max-height: calc(100vh - 10em);
	overflow: auto;
}

.notifications td {
	padding: 10px;
}

.dismiss-notify {
	position: absolute;
	right: -30px;
	top: -30px;
	font-weight: bold;
	font-size: 2em;
	width: 40px;
	height: 40px;
	min-width: unset;
	padding: 5px 10px;
	user-select: none;
	border: 1px #fd00ff solid;
	border-radius: 100%;
	display: flex;
	line-height: 0;
	align-items: center;
	justify-content: center;
}

.dismiss-notify::after {
	content: "\00D7";
	position: relative;
	top: 1px;
}

@media (min-width: 768px) {
	.notifications {
		top: 15px;
		bottom: unset;
		width: unset;
		display: unset;
		justify-content: unset;
	}
}
</style>
