export default class CurrencyTool {
    constructor(currencyInfo, cultureCode = "") {
        this.currencyInfo = currencyInfo;
        this.cultureCode = cultureCode;
    }

    displayType = {
        // Shows the number in terms of the major currency with fractional digits always.
        //Ex: $0.89, $4.75, $5.00, ¥17, 9.43 Pts
        full: "Full",
        // If the number is between 0 and 1 major units and the currency has a minor unit defined, this shows the
        // number in terms of the minor units.  Otherwise it displays as with Full (above).
        // Ex: 89¢, $4.75, $5.00, ¥17, 9.43 Pts
        minorOrFull: "MinorOrFull",
        // Shows the number of whole (integer) major units and without fractional digits, rounding if necessary.
        // Ex: $1, $5, $5, ¥17, 9 Pts
        whole: "Whole",
        // Shows the number as Whole (above) if there's no fractional part, otherwise shows it as Full (above).
        // Ex: $0.89, $4.75, $5, ¥17, 9.43 Pts
        wholeOrFull: "WholeOrFull",
        // If the number is between 0 and 1 major units and the currency has a minor unit defined, this shows the
        // number in terms of the minor units.  Otherwise, if there's no fractional part, it shows as Whole (above).
        // If neither of those cases is true, it shows like Full (above).
        // Ex: 89¢, $4.75, $5, ¥17, 9.43 Pts
        minorWholeOrFull: "MinorWholeOrFull",
    };

    // Rounds the given value to the currency's minor units.  (I.e., to the nearest $0.01 for USD, etc.)
    roundToMinorUnit(currencyValue = 0) {
        let rounded = Math.round((currencyValue + Number.EPSILON) * 100) / 100;
        rounded = rounded.toFixed(this.currencyInfo.minorDigits);
        return Number(rounded); // maybe try minorDigits
    }

    // Converts a value in AU (Accounting Units - the server's internal unit) to the currency type set for this
    // object, rounding to the appropriate number of decimal places for the currency type.
    toCurrencyFromAU(valueAU = 0) {
        return this.roundToMinorUnit(valueAU / this.currencyInfo.auPerCurrencyUnit);
    }

    // Converts a value in this object's currency type to AU (Accounting Units - the server's internal unit).
    // The value will be rounded to the nearest AU.  (Be careful if you've done any math or type conversions -
    // the rounding could cause problems.)
    toAUFromCurrency(currencyValue = 0) {
        return this.roundToMinorUnit(currencyValue) * this.currencyInfo.auPerCurrencyUnit;
    }

    // Format a Cashout Currency (COC) with the correct currency symbol and in the correct position.
    // currencyValue: Value in the user's Cashout Currency (COC).
    // currencyInfo: A property returned from Rabbitsfoot server after login at some casino.
    // minFracDigits: Minimum number of digits to the right of the decimal point, if
    // currencyInfo.minorDigits might not be enough. (This is for unusual cases.)
    // returns a string containing the value formatted nicely and decorated with the currency symbol specified in currencyInfo.
    formatCurrency(currencyValue = 0, display = {}, minFracDigits = 0) {
        let canDoAsMinor = (display == this.displayType.minorOrFull || display == this.displayType.minorWholeOrFull)
            && currencyValue < 1
            && currencyValue >= 0
            && this.currencyInfo.minorDigits > 0
            && this.currencyInfo.minorSymbol?.length > 0
            && minFracDigits <= this.currencyInfo.minorDigits;

        if (canDoAsMinor)
            return this.formatFromCurrencyAsMinor(currencyValue);

        let negativeSign = currencyValue < 0 ? "-" : "";
        let absoluteCurVal = Math.abs(currencyValue);

        let symbolInFront = this.currencyInfo.doesSymbolGoInFront;
        let symbolJoin = this.currencyInfo.spaceBetweenSymbol ? " " : "";

        let rawNumberString = this.formatRawFromCurrency(absoluteCurVal, display, minFracDigits);
        return symbolInFront
            ? `${negativeSign}${this.currencyInfo?.symbol ? this.currencyInfo.symbol : ""}${symbolJoin}${rawNumberString}`
            : `${negativeSign}${rawNumberString}${symbolJoin}${this.currencyInfo?.symbol ? this.currencyInfo.symbol : ""}`;
    }

    // Format a number as currency (with the right currency sign and number of digits), after converting from AU.
    // valueAU: Value in the server's Accounting Units (AU).
    // display: Dictates how to express certain cases: e.g., $0.89 vs 89¢, or $5 vs $5.00
    // returns String containing the value formatted nicely and decorated with currency symbols.
    formatFromAU(valueAU = 0, display = {}) {
        let currencyVal = this.toCurrencyFromAU(valueAU);
        return this.formatCurrency(currencyVal, display);
    }

    // Formats a non-integer AU value with potentially more precision than the currency's minor digits would
    // support.

    // This is for very unusual cases.  There's no such thing as a fractional AU, but there are a few cases where
    // some multiplier should be formatted as currency.  For example, "66.6667 AU credit per $1 cash" would
    // be best formatted as "$0.666667 credit per $1 cash".

    // valueAU: Value in the server's Accounting Units (AU).

    // meaningfulFracAUDigits: The number of digits to the right of the decimal place that are
    // meaningful in AU and should be shown.  The actual number of digits displayed will be more if
    // currencyInfo.auPerCurrencyUnit is greater than 1.

    // Returns a string containing the value formatted nicely and decorated with currency symbols,
    // potentially with more fractional digits than you'd normally see for the currency.
    formatFromFractionalAU(valueAU, meaningfulFracAUDigits) {
        let currencyVal = valueAU / this.currencyInfo.auPerCurrencyUnit;
        let auDigitsPerUnit = Math.ceil(Math.log10(this.currencyInfo.auPerCurrencyUnit));
        return this.formatCurrency(currencyVal, this.displayType.full, meaningfulFracAUDigits + auDigitsPerUnit);
    }

    formatAsIso(currencyValue) {
        let rawNumberString = this.formatRawFromCurrency(currencyValue, this.displayType.full);
        if (this.currencyInfo.iso4217Code?.length <= 0)
            return rawNumberString;

        // According to Wikipedia, ISO doesn't dictate formatting when writing a number with its ISO code,
        // but the EU has rules about when the code comes first or last.
        // https://en.wikipedia.org/wiki/ISO_4217#Code_position_in_amount_formatting
        let codeComesFirst = this.cultureCode.includes("en")
            || this.cultureCode.includes("ga")
            || this.cultureCode.includes("lv")
            || this.cultureCode.includes("mt");
        return codeComesFirst
            ? `${this.currencyInfo.iso4217Code ? this.currencyInfo.iso4217Code : ""} ${rawNumberString}`
            : `${rawNumberString} ${this.currencyInfo.iso4217Code ? this.currencyInfo.iso4217Code : ""}`;
    }

    // Formats a currency value as a number - with the right number of digits and separators - but without any
    // currency symbols.
    // rawNumber: Value in the currency's major units (e.g., dollars, not cents or AU).
    // display: Dictates how to express certain cases: e.g., 5 vs 5.00
    // returns a String containing the value formatted nicely but without currency symbols.
    formatRawFromCurrency(rawNumber = 0, display = {}, minFracDigits = 0) {
        let useWhole = (display === this.displayType.whole)
            || ((display === this.displayType.wholeOrFull || display === this.displayType.minorWholeOrFull)
                && rawNumber === Math.round(rawNumber)
                && minFracDigits <= 0);

        if (useWhole)
            return rawNumber.toLocaleString(this.cultureCode, {
                maximumFractionDigits: 0
            });

        let formatString = minFracDigits <= this.currencyInfo.minorDigits
            ? rawNumber.toLocaleString(this.cultureCode, {
                minimumFractionDigits: this.currencyInfo.minorDigits,
                maximumFractionDigits: this.currencyInfo.minorDigits
            })
            : rawNumber.toLocaleString(this.cultureCode, {
                minimumFractionDigits: minFracDigits,
                maximumFractionDigits: minFracDigits
            });

        return formatString;
    }

    // Displays the given currency value converted to the currency's minor units (which are assumed to be defined) and
    // decorated with the minor unit's symbol.
    formatFromCurrencyAsMinor(currencyValue = 0) {
        let minorMultiple = Math.pow(10, this.currencyInfo.minorDigits);
        let minorValue = Math.trunc(currencyValue * minorMultiple);
        let minorString = minorValue.toLocaleString(this.cultureCode, {
            maximumFractionDigits: 0
        });
        let symbolInFront = this.currencyInfo.doesMinorSymbolGoInFront;
        let symbolJoin = this.currencyInfo.spaceBetweenSymbol ? " " : "";
        let symbol = this.currencyInfo.minorSymbol;
        return symbolInFront
            ? `${symbol ? symbol : ""}${symbolJoin}${minorString}`
            : `${minorString}${symbolJoin}${symbol ? symbol : ""}`;
    }
}
