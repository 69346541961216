<template>
	<div class="home gradient-background">
		<img
			v-if="countryCode == 'MX'"
			class="home-logo-lg"
			src="/styleOverrides/EncoreOnlinme_MX_LogoNav-p-800.png"
			alt="encoreonline.mx"
		/>
		<h1 v-if="cashierState?.isLoggedOn && !cashierState?.permissionsSuspended && casinoListForUser.length > 0">
			{{ languageStrings.selectCasino }}
		</h1>
		<h3 v-else class="align-center">{{ languageStrings.noCashierAccess }}</h3>
		<DropdownMenu
			v-if="casinoListForUser.length > 0"
			:casinoList="casinoListForUser"
			:defaultCasinoId="casinoId"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
		<div class="button-container" v-if="selectedCasinoId">
			<router-link
				class="btn"
				v-if="cashierState?.isLoggedOn && !cashierState?.permissionsSuspended && cashierState?.isCashier && cashierState?.casinoId"
				to="/openCashierBank"
				>{{ languageStrings.openBank }}</router-link
			>
			<router-link
				class="btn"
				v-if="
					cashierState?.isLoggedOn &&
						!cashierState?.permissionsSuspended &&
						cashierState?.casinoId &&
						(cashierState?.isCashier || cashierState?.isSiteAdmin || cashierState?.isManager || cashierState?.isHelpDesk)
				"
				to="/listCashierBanks"
				>{{ languageStrings.listBanks }}</router-link
			>
		</div>
		<div v-if="cashierState?.isLoggedOn && selectedCasinoId" class="limitations-container">
			<p v-if="cashierState?.permissionsSuspended">
				{{ languageStrings.temporarilySuspended }}
			</p>

			<p v-if="cashierState?.isLoggedOn && !cashierState?.isCashier && !cashierState?.isSiteAdmin && !cashierState?.isHelpDesk && devMode">
				{{ cashierState?.casinoName }} banks.
			</p>
			<p v-if="cashierState?.isLoggedOn && !cashierState?.isCashier && cashierState?.isSiteAdmin && devMode">
				{{ languageStrings.noAccessToaccessTransactions }} {{ cashierState?.casinoName }}.
			</p>
			<p v-if="cashierState?.isLoggedOn && !cashierState?.isCashier && devMode">
				{{ languageStrings.noAccessToOpenBank }} {{ cashierState?.casinoName ? `at ${cashierState.casinoName}` : "" }}.
			</p>
			<p v-if="cashierState?.isLoggedOn && !serialSupported" class="no-serial" :title="languageStrings.noSerialSupport">
				{{ languageStrings.noSerialSupport }}
			</p>
		</div>
	</div>
</template>

<script>
import DropdownMenu from "@/components/DropdownMenu";
import { onBeforeUnmount } from "vue";

export default {
	name: "SelectCasino",
	components: {
		DropdownMenu,
	},
	props: {
		cashierState: Object,
		casinoList: Array,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			casinoListForUser: [],
			selectedCasinoId: this?.cashierState?.casinoId || 0,
			selectedCasino: this?.selectedCasino || {},
			casinoId: this?.cashierState?.casinoId || 0,
		};
	},
	created() {
		this.setupCasinoSelection();

		this.eventBus.on("loginSucess", () => {
			this.listCasinosForThisUser();
		});
		this.eventBus.on("casinoSelected", (payload) => {
			this.selectedCasinoId = payload.id;
			this.selectedCasino = payload;
		});
		this.eventBus.on("casinoListRetrieved", () => {
			this.listCasinosForThisUser();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("loginSucess");
			this.eventBus.off("casinoSelected");
			this.eventBus.off("casinoListRetrieved");
		});
	},
	mounted() {
		this.listCasinosForThisUser();
	},
	watch: {
		selectedCasinoId() {
			this.setupCasinoSelection();
		},
	},
	methods: {
		setupCasinoSelection() {
			if (!this.selectedCasino?.id) return false;

			this.selectedCasino = this.casinoListForUser.filter((casino) => casino?.id == this.selectedCasinoId)[0];
			let _state = this.cashierState;
			_state.selectedCasino = this.cashierState?.isLoggedOn ? this.selectedCasino : {};
			_state.casinoName = this.cashierState?.isLoggedOn ? this.selectedCasino.name : "";
			_state.currencyInfo = this.selectedCasino?.currencyInfo;
			_state.casinoId = this.selectedCasino?.id;
			_state.sitePermissions = _state.userPermissions?.sitePermissions;
			_state.isSiteAdmin = _state.sitePermissions?.[_state.casinoId]?.includes("SiteAdmin");
			_state.isCashier = _state.sitePermissions?.[_state.casinoId]?.includes("Cashier");
			_state.isReporter = _state.sitePermissions?.[_state.casinoId]?.includes("Reporter");
			_state.isManager = _state.sitePermissions?.[_state.casinoId]?.includes("Manager");
			_state.isHelpDesk = _state.userPermissions?.globalPermissions?.includes("HelpDesk");
			this.eventBus.emit("updateCashierState", _state);
		},
		listCasinosForThisUser() {
			let permissionsList = this.cashierState?.userPermissions?.sitePermissions;
			this.casinoListForUser = [];

			for (const key in permissionsList) {
				if (
					(permissionsList[key].includes("SiteAdmin") ||
						permissionsList[key].includes("Manager") ||
						permissionsList[key].includes("Cashier")) &&
					!this.cashierState?.permissionsSuspended
				) {
					let casino = this.casinoList.filter((casino) => casino?.id == key && !casino?.isDeleted && casino.isEnabled)[0];
					if (casino) this.casinoListForUser.push(casino);
				}
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	text-align: center;
	margin-top: 0;
	padding-top: 25px;
	text-shadow: -2px -2px 0px #000, 2px -2px 0px #000, -2px 2px 0px #000, 2px 2px 0px #000;
}

/* .home {
	height: calc(100vh - 150px);
	width: 80%;
	margin-left: 20%;
	margin-top: 0;
} */

.limitations-container p {
	width: 60%;
	padding: 15px 30px;
	margin: auto;
	background-color: rgb(67 66 80 / 75%);
	backdrop-filter: blur(6px);
	color: #ff0;
	text-align: center;
	font-weight: bold;
}

.limitations-container p:first-child {
	margin-top: 15px;
}

.limitations-container p + p {
	padding-top: 0 !important;
}

.button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 15px 0 0;
}

.dropdown {
	margin: auto;
	display: flex;
	flex-direction: column;
	width: 20em;
	align-items: center;
}
</style>
