function isValidOpenValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= 0 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

function isValidTransferValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= -1000000000 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

async function getCasinoList(context, casinoId = null) {
    try {
        let requestUrl = new URL(`/api/v1/site/${casinoId || ""}`, context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: 'GET',
            headers: headerObj,
        });
        const response = await fetch(request);
        let dataObj = await response.json();

        return dataObj;
    } catch (e) {
        console.error(e);
    }
}

async function getBanksList(context, isOpen = null, limit = null, offset = null) {
    try {
        let headerObj = new Headers();
        headerObj.append("Authorization", `Bearer ${context.cashierState.accessToken}`);
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let requestUrl = new URL("/api/v1/cashier/bank", context.rabbitsfootHostUrl);
        let params = requestUrl.searchParams;

        params.set("casinoId", context.cashierState.casinoId);

        if (context.isOpen || isOpen)
            params.set("isOpen", isOpen ? isOpen : context.isOpen);

        if (context.offset || offset)
            params.set("offset", offset ? offset : context.offset);

        if (context.limit || limit)
            params.set("limit", limit ? limit : context.limit);

        if (!(context.isSiteAdmin || context.isManager || context.isHelpDesk))
            params.set("cashierUserId", context.cashierState.userId);

        requestUrl.search = params.toString();

        let request = new Request(
            requestUrl.toString(), {
            method: 'GET',
            headers: headerObj,
        });

        let status = null;

        let response = await fetch(request);
        let dataJson = response.ok == true ? await response.json() : response;
        return dataJson;

    } catch (e) {
        console.error(e);
    }
}

function checkFetchErrors(fetchResponse, languageErrorStrings) {
    let status = {
        code: fetchResponse?.status?.code ? fetchResponse.status.code : fetchResponse.status,
        message: "",
        ok: true,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    if ((!fetchResponse.length > 0 && !fetchResponse.ok) || fetchResponse.status >= 400) {
        status.ok = false;
        switch (fetchResponse.status) {
            case 400:
                status.message = languageErrorStrings.badRequest;
                break;
            case 401:
                status.message = languageErrorStrings.sessionExpired;
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 403:
                status.message = languageErrorStrings.noPermissions;
                status.userMustDismiss = true;
                break;
            case 404:
                status.message = languageErrorStrings.notFound;
                status.userMustDismiss = true;
                break;
            case 409:
                status.message = languageErrorStrings.accountAccessedFromOtherDevice;
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 423:
                // 423 is the code we are using for locked user account
                status.message = "Your account has been locked. Please contact support.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 500:
                status.message = languageErrorStrings.internalServerError;
                break;
            case 503:
                status.message = languageErrorStrings.serviceUnavailable;
                break;
            case undefined:
                status.code = null;
                status.message = languageErrorStrings.noResults;
                break;
            default:
                status.message = fetchResponse.status;
                status.userMustDismiss = true;
        }
        // console.error(status);
    }
    return status;
}

async function getCurrentBank(context) {
    try {
        let banks = await getBanksList(context);
        let openBank = await banks.find(bank => bank.closeDate === null);
        let fetchStatus = checkFetchErrors(bankList);

        if (fetchStatus && !fetchStatus.ok) {
            return fetchStatus;
        }

        if (openBank?.id) {
            return openBank;
        }
        return fetchStatus;
    }
    catch (e) {
        console.error(e);
    }
}

function checkSuccessErrors(serverResponseJson, languageErrorStrings) {
    // errors that are a server 200 but still are an error state for this application.
    // setting code to null even though it was 200 so that 200 doesn't show in the status block of the site header
    let status = {
        code: null,
        message: null,
        ok: true,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    let statusList = [
        "Success",
        "InvalidUser",
        "MiscellaneousError",
        "PasswordTooShort",
        "PasswordTooFewCharacterClasses",
        "PasswordNotAllowed",
        "PasswordIncorrect",
        "DisplayNameTooShort",
        "PhoneNumberInvalidCharacters",
        "PhoneNumberAlreadyInUse",
        "UnableToSendCode",
        "InvalidCode",
        "FailedToSend",
        "TooManyRequests",
        "UserAccountIsLocked"
    ];

    status.ok = !statusList.includes(serverResponseJson) ? true : false;

    switch (serverResponseJson) {
        case statusList[0]:
            // "Success"
            status.ok = true;
            status.message = languageErrorStrings.success;
            break;
        case statusList[1]:
            // "InvalidUser"
            status.message = languageErrorStrings.invalidUser;
            break;
        case statusList[2]:
            // "MiscellaneousError"
            status.message = languageErrorStrings.unknownErrorTryAgain;
            break;
        case statusList[3]:
            // "PasswordTooShort"
            status.message = languageErrorStrings.passwordTooShort;
            break;
        case statusList[4]:
            // "PasswordTooFewCharacterClasses"
            status.message = languageErrorStrings.tooFewCharacterClasses;
            break;
        case statusList[5]:
            // "PasswordNotAllowed"
            status.message = languageErrorStrings.passwordNotAllowed;
            break;
        case statusList[6]:
            // "PasswordIncorrect"
            status.message = languageErrorStrings.currentPasswordIncorrect;
            break;
        case statusList[7]:
            // "DisplayNameTooShort"
            status.message = languageErrorStrings.displayNameTooShort;
            break;
        case statusList[8]:
            // "PhoneNumberInvalidCharacters"
            status.message = languageErrorStrings.phoneNumberInvalidCharacters;
            break;
        case statusList[9]:
            // "PhoneNumberAlreadyInUse"
            status.message = languageErrorStrings.phoneNumberInUse;
            break;
        case statusList[10]:
            // "UnableToSendCode"
            status.message = languageErrorStrings.unableToSendCode;
            break;
        case statusList[11]:
            // "InvalidCode"
            status.message = languageErrorStrings.invalidConfirmationCode;
            break;
        case statusList[12]:
            // "FailedToSend"
            status.message = languageErrorStrings.unableToSendSMS;
            break;
        case statusList[13]:
            // "TooManyRequests"
            status.message = languageErrorStrings.tooManyRequests;
            break;
        case statusList[14]:
            // "UserAccountIsLocked"
            status.ok = false;
            status.message = languageErrorStrings.lockedAccountMessage;
            break;
        default:
            status.message = languageErrorStrings.unknownError;
            status.ok = false;
    };

    return status;
}

// function logout(context = null) {
//     this.session.deleteAll();
// }

async function checkAndRefreshSession(vueInstance, state) {
    let status = Object.assign({}, vueInstance.globalStatus);
    let responseJSON = {};

    // Comparing old/new access tokens with 30 seconds wiggle room for potential server latency.
    if (state.accessToken && new Date().getTime() > new Date(state.accessTokenExpiration).getTime() - 30000) {
        let body = {
            oldAccessToken: state.accessToken,
            refreshToken: state.refreshToken,
        };

        let requestUrl = new URL("/api/v1/authentication/refresh", vueInstance.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: "POST",
            body: JSON.stringify(body),
            headers: headerObj,
        });

        try {
            const response = await fetch(request);

            let fetchStatus = checkFetchErrors(response);

            if (!fetchStatus.ok || response === "Bad OldAccessToken") {
                console.error(response);
                return fetchStatus || response;
            }

            responseJSON = await response.json();

            state.accessTokenExpiration = responseJSON.accessTokenExpiration;
            state.accessToken = responseJSON.accessToken;
            state.refreshToken = responseJSON.refreshToken;

            if (responseJSON.accessToken) fetchStatus.message = "Session refreshed by server";
            return state;
        } catch (e) {
            status.message(`checkAndRefreshSession returned: ${e}`);
            status.ok = false;
            return status;
        }
    } else if (state.accessToken) {
        status.message = "Session from cache";
    }
    return status;
}

const sharedScripts = {
    getBanksList,
    getCasinoList,
    checkFetchErrors,
    getCurrentBank,
    isValidOpenValue,
    isValidTransferValue,
    checkSuccessErrors,
    checkAndRefreshSession,
}

export default sharedScripts;